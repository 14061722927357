<template>
  <div>
    <img
      :src="require('@/assets/images/background/contact-us-bg.png')"
      class="header__background"
    >
    <v-container class="mb-10">
      <div class="d-flex flex-column justify-center mt-8 mb-4">
        <span class="contact__us__header">ติดต่อเรา</span>
      </div>
      <v-divider />
      <v-row class="mt-6 mb-10">
        <v-col
          cols="12"
          md="6"
        >
          <ContactUsChannel />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <ContactUsContactBox />
        </v-col>
      </v-row>
    </v-container>
    <google-map
      id="map"
      ref="Map"
      :center="center"
      :zoom="zoom"
      style="height: 500px"
    >
      <google-map-marker
        :clickable="clickable"
        :draggable="draggable"
        :position="infoWIndowContext.position"
      />
    </google-map>
  </div>
</template>

<script>
import ContactUsChannel from './components/ContactUsChannel.vue';
import ContactUsContactBox from './components/ContactUsContactBox.vue';

export default {
  name: 'AboutUs',
  components: {
    ContactUsChannel,
    ContactUsContactBox,
  },
  data() {
    return {
      draggable: true,
      clickable: true,
      zoom: 15,
      center: {
        lat: 15.8098228,
        lng: 102.0296141,
      },
      infoWIndowContext: {
        position: {
          lat: 15.8098228,
          lng: 102.0296141,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.header__background {
  width: 100%;
  height: 260px;
}

.contact__us__header {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
}
</style>
