<template>
  <div>
    <div class="mb-10"> 
      <span class="contact__us__detail__header__2">ที่อยู่ร้านขายยา</span>
      <div class="d-flex align-center mt-2">
        <img :src="require('@/assets/images/icon/placeholder.png')">
        <span class="contact__us__detail__text ml-2">
          <span class="contact__us__detail__bold">สาขา 1:</span>
          273/35 ถนนยุติธรรม ตำบลในเมือง อำเภอเมือง จังหวัดชัยภูมิ 36000
        </span>
      </div>
      <div class="d-flex align-center">
        <img :src="require('@/assets/images/icon/placeholder.png')">
        <span class="contact__us__detail__text ml-2">
          <span class="contact__us__detail__bold">สาขา 2:</span>
          เยื้องประปาชัยภูมิ 291 ถ.ชัยภูมิ-สีคิ้ว ต.ในเมือง อ.เมืองชัยภูมิ จ.ชัยภูมิ 36000
        </span>
      </div>
    </div>
    <div class="my-10">
      <span class="contact__us__detail__header__2">เวลาเปิด-ปิด</span>
      <div class="d-flex mt-2">
        <div class="d-flex align-center">
          <img :src="require('@/assets/images/icon/school-calendar.png')">
          <span class="contact__us__detail__text ml-2">จันทร์ - เสาร์</span>
        </div>
        <div class="d-flex align-center ml-5">
          <img :src="require('@/assets/images/icon/clock.png')">
          <span class="contact__us__detail__text ml-2">08:00 น. - 18:00 น.</span>
        </div>
      </div>
    </div>
    <div class="my-10">
      <span class="contact__us__detail__header__2">เบอร์โทรติดต่อ</span>
      <div class="d-flex align-center mt-2">
        <img :src="require('@/assets/images/icon/path.png')">
        <span class="contact__us__detail__text ml-2">088-886-8628</span>
      </div>
    </div>
    <div class="my-10">
      <span class="contact__us__detail__header__2">อีเมล</span>
      <div class="d-flex align-center mt-2">
        <img
          :src="require('@/assets/images/icon/gmail.png')"
          height="14"
        >
        <span class="contact__us__detail__text ml-2">-</span>
      </div>
    </div>
    <div class="my-10">
      <span class="contact__us__detail__header__2">ช่องทางติดต่อผ่าน Social media</span>
      <div class="d-flex mt-2">
        <div class="d-flex align-center">
          <img :src="require('@/assets/images/icon/icons-8-line-copy.png')">
          <span class="contact__us__detail__text ml-2">{{ getUserLineId() }}</span>
        </div>
        <div class="d-flex align-center ml-5">
          <img :src="require('@/assets/images/icon/f-logo-rgb-blue-1024-copy.png')">
          <span class="contact__us__detail__text ml-2">narinbhaesaj</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactUsChannel',
  computed: {
    ...mapGetters('user', ['getUserInfo']),
  },
  methods: {
    getUserLineId() {
      return !this.getUserInfo || this.getUserInfo.level == 0 ? '@narinch' : '@narinonline'
    },
  },
};
</script>

<style lang="scss">
.contact__us__detail__header__2 {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
}

.contact__us__detail__bold {
  font-weight: 600;
}


.contact__us__detail__text {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  color: #242424;
}
</style>
