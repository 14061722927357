<template>
  <div class="d-flex flex-column"> 
    <span class="contact__us__detail__header">Contact Box</span>
    <v-row class="mt-4">
      <v-col
        cols="12"
        md="6"
        class="py-0"
      >
        <TextField
          :value="customerName"
          place-holder="ชื่อ - นามสกุล"
          @setValue="setCustomerName"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="py-0"
      >
        <TextField
          :value="customerPhone"
          place-holder="เบอร์โทรศัพท์ ผู้ติดต่อ"
          @setValue="setCustomerPhone"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <TextField
          :value="customerEmail"
          place-holder="อีเมลผู้ติดต่อ"
          @setValue="setCustomerEmail"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <TextField
          :value="contactHeader"
          place-holder="เรื่องที่ต้องการติดต่อ"
          @setValue="setContactHeader"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-textarea
          v-model="contactDetail"
          outlined
          height="220"
          placeholder="รายละเอียด"
        />
      </v-col>
    </v-row>
    <v-btn
      class="px-12 contact__us__btn"
      :loading="loading"
      :disabled="loading"
      @click="onSubmit"
    >
      <span>ส่งข้อความ</span>
    </v-btn>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR } from '@/store/_actionTypes';

import TextField from '@/components/TextField.vue';

export default {
  name: 'ContactUsContactBox',
  components: {
    TextField,
  },
  data: () => ({
    loading: false,
    customerName: '',
    customerPhone: '',
    customerEmail: '',
    contactHeader: '',
    contactDetail: '',
  }),
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE]),
    setCustomerName(value) {
      this.customerName = value;
    },
    setCustomerPhone(value) {
      this.customerPhone = value;
    },
    setCustomerEmail(value) {
      this.customerEmail = value;
    },
    setContactHeader(value) {
      this.contactHeader = value;
    },
    onSubmit() {
      this.loading = true;
      setTimeout(() => {
        this[SHOW_SNACKBAR](true);
        this[CHANGE_SNACKBAR_MESSAGE]('ส่งคำร้องสำเร็จ');
        this.loading = false;
        this.customerName = '';
        this.customerPhone = '';
        this.customerEmail = '';
        this.contactHeader = '';
        this.contactDetail = '';
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.contact__us__detail__header {
  font-family: Kanit;
  font-size: 20px;
  line-height: 1.8;
  color: #242424;
}

.contact__us__btn {
  font-family: Kanit;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: unset;
  background-color: $primary-color-02 !important;
  align-self: flex-end;

  & span {
    color: $white-01;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: Kanit;
  font-size: 14px;
  line-height: 1.57;
  color: #242424;
}
</style>
